export default function MarketingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      className="h-[2.25rem] w-[2.25rem]"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2223 23.8794C28.5707 22.4317 25.887 20.904 21.9112 20.904H15.8668V8.81585H21.9112C25.887 8.81585 28.5707 7.28812 30.2223 5.84047V23.8794ZM17.3779 30.3492H14.1857C13.7799 28.7119 12.5189 25.528 10.7811 23.1714H17.3779V30.3492ZM3.7779 14.8595C3.7779 11.5268 6.48959 8.81585 9.82234 8.81585H13.6001V20.904H9.82234C6.48959 20.904 3.7779 18.1931 3.7779 14.8595ZM31.6367 1.67585C31.1366 1.54816 30.6115 1.77558 30.3606 2.22892C30.2631 2.40496 27.9081 6.54918 21.9112 6.54918H9.82234C5.2399 6.54918 1.51123 10.2771 1.51123 14.8595C1.51123 18.4862 3.85043 21.5696 7.09554 22.7022C9.20581 23.8121 11.5548 28.792 12.0339 31.0994C12.2144 31.9782 12.9927 32.6159 13.8842 32.6159H17.7557C18.7976 32.6159 19.6446 31.7681 19.6446 30.727V23.1714H21.9112C27.9081 23.1714 30.2631 27.3149 30.3583 27.4856C30.5586 27.8596 30.9462 28.0818 31.3557 28.0818C31.4471 28.0818 31.54 28.0712 31.633 28.0478C32.1362 27.9208 32.489 27.4683 32.489 26.9484V2.77141C32.489 2.25385 32.1377 1.80505 31.6367 1.67585Z"
        fill="black"
      />
    </svg>
  );
}
