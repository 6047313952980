import HeadingGroup, {
  type HeadingGroupContentProps,
  type HeadingGroupProps,
} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import type {Mode} from '@/components/base/types';

interface CardProps {
  headingGroup?: HeadingGroupContentProps & {
    headingAs?: HeadingGroupProps['headingAs'];
  };
  horizontal?: boolean;
  icon?: React.ReactNode;
  size?: 'large' | 'small';
  link: string;
}

export default function Card({
  headingGroup,
  icon,
  size = 'large',
  link,
}: CardProps) {
  const headingGroupProps = {
    ...headingGroup,
    mode: 'light' as Mode,
    size: size === 'large' ? 't3' : 't6',
  };

  return (
    <a
      className="rounded-2xl ring-0 ring-black/20 border-black p-6 shadow-light hover:bg-slate-50 transition-colors"
      href={`/partners/directory/services/${link}`}
    >
      {icon && <div className="mb-4">{icon}</div>}
      <div>{headingGroup && <HeadingGroup {...headingGroupProps} />}</div>
    </a>
  );
}
