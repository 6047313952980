import cn from 'classnames';
import {type SyntheticEvent, useRef} from 'react';

import Image from '@/components/base/elements/Image/Image';
import Section from '@/components/base/layouts/Section/Section';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import type {HeroProps} from '@/components/base/sections/Hero/types';
import {ColorSchemeEnum} from '@/enums';
import {heroBackgroundByTheme} from '@/stylesheets/brochureV2/colorSchemes';
import {twMerge} from '@/stylesheets/twMerge';
import {useSharedTranslations} from '@/hooks/useTranslations';
import {useSearchFormHelpers} from '@/pages/shopify.com/($locale)/partners/directory/utilities/useSearchFormHelpers';
import {CancelIcon} from '@/pages/shopify.com/($locale)/partners/directory/components/CancelIcon/CancelIcon';

import SearchFlyout from '../Header/components/Search/components/SearchFlyout';

export default function HeroFloatingImageWithSearch({
  className,
  colorScheme,
  ctas,
  headingGroupProps,
  mode = 'light',
  videos,
  isBleedImage,
  ...props
}: HeroProps) {
  const scheme = colorScheme || ColorSchemeEnum.Lavender;
  const {t} = useSharedTranslations('pages/partners/directory/shared');

  const {
    formRef,
    Form,
    showFlyout,
    partners,
    services,
    countries,
    dismissSearchResults,
    onChange,
    errors,
  } = useSearchFormHelpers();

  const searchInputRef = useRef<HTMLInputElement>(null);

  return (
    <Section
      className={twMerge(
        cn('relative pb-3xl xs:!pb-0 items-start', {
          'pb-0': isBleedImage,
        }),
        heroBackgroundByTheme[scheme],
        className,
      )}
      errorBoundaryFullWidth={true}
      navbarPadding={true}
      {...props}
    >
      <TwoColumn className="pt-16 pb-4 md:py-20">
        <TwoColumn.Col1 className="flex flex-col col-span-12 md:col-span-6 gap-y-md justify-center">
          <HeadingGroup
            kicker={headingGroupProps?.kicker}
            headingAs="h1"
            size="t1"
            headingHtml={headingGroupProps?.headingHtml}
            subheadHtml={headingGroupProps?.subheadHtml}
            mode="dark"
          />
          <Form
            ref={formRef}
            className="hidden md:flex min-[1271px]:w-[33.373125rem] flex-col justify-center relative"
            onChange={onChange}
          >
            <div className="box-border flex h-12 md:h-16 w-full md:w-full items-center justify-between rounded-full border border-shade-30 pl-6 pr-1 bg-white overflow-hidden">
              <input
                ref={searchInputRef}
                className="appearance-none text-shade-70 h-full py-4 w-full outline-none placeholder:text-subdued"
                type="text"
                name="directory-search"
                placeholder={t('home.hero.search.placeholder')}
              />

              {showFlyout ? (
                <>
                  <button
                    type="button"
                    name="clear_search_for_hero_search"
                    className="w-5 h-5 mr-4"
                    onClick={(event: SyntheticEvent) => {
                      event?.preventDefault();
                      if (searchInputRef.current) {
                        searchInputRef.current.value = '';
                      }
                    }}
                  >
                    <CancelIcon />
                  </button>
                  <SearchFlyout
                    partners={partners || []}
                    services={services || []}
                    countries={countries || []}
                    dismissSearchResults={dismissSearchResults}
                    formRef={formRef}
                    errors={errors}
                  />
                </>
              ) : null}
            </div>
          </Form>
        </TwoColumn.Col1>
        <TwoColumn.Col2
          className={cn(
            'sm:col-start-1 col-span-12 sm:col-span-8 my-auto pb-2xl md:pb-0',
            {
              '!py-0 flex-row h-full items-end': isBleedImage,
            },
          )}
        >
          <Image
            className="mx-auto"
            src="https://cdn.shopify.com/b/shopify-brochure2-assets/1d4d8c0ac413787b8341bedab3c2ba20.png?originalWidth=1968&originalHeight=1788"
            alt="Shopify Partners"
            sources={[
              {
                media: '(max-width: 899px)',
                srcSet:
                  'https://cdn.shopify.com/b/shopify-brochure2-assets/14ac5e9f83beeaf19f0bdfa32de21348.png?originalWidth=1528&originalHeight=1388',
              },
              {
                media: '(min-width: 900px)',
                srcSet:
                  'https://cdn.shopify.com/b/shopify-brochure2-assets/1d4d8c0ac413787b8341bedab3c2ba20.png?originalWidth=1968&originalHeight=1788',
              },
            ]}
          />
        </TwoColumn.Col2>
      </TwoColumn>
    </Section>
  );
}
