export default function UserProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      className="h-[2.25rem] w-[2.25rem]"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9996 16.7522C15.1251 16.7522 13.5996 15.2268 13.5996 13.3522C13.5996 11.4777 15.1251 9.95222 16.9996 9.95222C18.8742 9.95222 20.3996 11.4777 20.3996 13.3522C20.3996 15.2268 18.8742 16.7522 16.9996 16.7522ZM16.9996 7.68556C13.8694 7.68556 11.333 10.2227 11.333 13.3522C11.333 16.4817 13.8694 19.0189 16.9996 19.0189C20.1299 19.0189 22.6663 16.4817 22.6663 13.3522C22.6663 10.2227 20.1299 7.68556 16.9996 7.68556ZM26.2197 26.5888C25.477 23.4668 22.9005 21.2856 19.6441 21.2856H14.733C11.4153 21.2856 8.62505 23.5507 7.80603 26.613C5.3263 24.208 3.77741 20.8488 3.77741 17.13C3.77741 9.83965 9.70928 3.90778 16.9996 3.90778C24.29 3.90778 30.2219 9.83965 30.2219 17.13C30.2219 20.836 28.6851 24.1854 26.2197 26.5888ZM9.83394 28.2261C9.95936 25.6285 12.1051 23.5522 14.733 23.5522H19.6441C22.1963 23.5522 24.0633 25.5038 24.1668 28.2246C22.0996 29.5649 19.6418 30.3522 16.9996 30.3522C14.3582 30.3522 11.9011 29.5657 9.83394 28.2261ZM16.9996 1.64111C8.44523 1.64111 1.51074 8.5756 1.51074 17.13C1.51074 25.6844 8.44523 32.6189 16.9996 32.6189C25.554 32.6189 32.4885 25.6844 32.4885 17.13C32.4885 8.5756 25.554 1.64111 16.9996 1.64111Z"
        fill="black"
      />
    </svg>
  );
}
