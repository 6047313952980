export default function DesignIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      className="h-[2.25rem] w-[2.25rem]"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4444 30.352H30.2222V20.5298H26.4444V30.352ZM25.2196 16.3743L19.2666 10.4212L16.6222 13.0657V24.9717L25.2196 16.3743ZM24.1777 20.6212C24.1777 20.6212 14.9614 30.037 14.6645 30.352H24.1777V20.6212ZM4.53327 8.44093H14.3555V4.66315H4.53327V8.44093ZM14.3555 10.7076H4.53327V25.4409C4.53327 28.1488 6.73647 30.352 9.44438 30.352C12.1523 30.352 14.3555 28.1488 14.3555 25.4409V10.7076ZM20.8691 8.81871L26.8222 14.7717L29.753 11.8409L23.7999 5.88791L20.8691 8.81871ZM32.4888 19.3965V31.4854C32.4888 32.111 31.9818 32.6187 31.3555 32.6187H9.44438C5.48678 32.6187 2.2666 29.3985 2.2666 25.4409V3.52982C2.2666 2.90422 2.77358 2.39648 3.39993 2.39648H15.4888C16.1152 2.39648 16.6222 2.90422 16.6222 3.52982V9.86062L22.9983 3.48373C23.441 3.04173 24.1588 3.04173 24.6016 3.48373L32.1571 11.0393C32.5991 11.482 32.5991 12.1998 32.1571 12.6426L26.5358 18.2632H31.3555C31.9818 18.2632 32.4888 18.7709 32.4888 19.3965ZM7.93327 25.4409C7.93327 24.6068 8.61025 23.9298 9.44438 23.9298C10.2785 23.9298 10.9555 24.6068 10.9555 25.4409C10.9555 26.2751 10.2785 26.952 9.44438 26.952C8.61025 26.952 7.93327 26.2751 7.93327 25.4409Z"
        fill="black"
      />
    </svg>
  );
}
