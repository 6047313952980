export default function DevelopmentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      className="h-[2.25rem] w-[2.25rem]"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.20621 17.4898C7.79896 17.0146 7.85336 16.2991 8.32861 15.8918C8.80536 15.4846 9.51936 15.5398 9.92661 16.0142L12.1933 18.6587C12.5575 19.0833 12.5575 19.7097 12.1933 20.1343L9.92661 22.7787C9.70221 23.0402 9.38563 23.1739 9.06603 23.1739C8.80461 23.1739 8.54243 23.0847 8.32861 22.9011C7.85336 22.4939 7.79896 21.7784 8.20621 21.3031L9.84048 19.3961L8.20621 17.4898ZM15.1112 21.6628C15.1112 21.0372 15.619 20.5294 16.2446 20.5294H21.1557C21.7813 20.5294 22.289 21.0372 22.289 21.6628C22.289 22.2891 21.7813 22.7961 21.1557 22.7961H16.2446C15.619 22.7961 15.1112 22.2891 15.1112 21.6628ZM15.1112 17.1294C15.1112 16.5038 15.619 15.9961 16.2446 15.9961H24.1779C24.8035 15.9961 25.3112 16.5038 25.3112 17.1294C25.3112 17.7558 24.8035 18.2628 24.1779 18.2628H16.2446C15.619 18.2628 15.1112 17.7558 15.1112 17.1294ZM3.7779 28.085H30.2223V11.4628H3.7779V28.085ZM3.7779 9.19612H8.31123V6.17389H3.7779V9.19612ZM10.5779 9.19612H30.2223V6.17389H10.5779V9.19612ZM30.9779 3.90723H3.02234C2.18745 3.90723 1.51123 4.5842 1.51123 5.41834V28.8406C1.51123 29.6754 2.18745 30.3517 3.02234 30.3517H30.9779C31.812 30.3517 32.489 29.6754 32.489 28.8406V5.41834C32.489 4.5842 31.812 3.90723 30.9779 3.90723Z"
        fill="black"
      />
    </svg>
  );
}
