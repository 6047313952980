import {useLoaderData} from '@remix-run/react';
import type {
  LoaderFunctionArgs,
  LoaderFunction,
  HeadersFunction,
} from '@remix-run/cloudflare';
import {json} from '@remix-run/cloudflare';
import {useMemo} from 'react';

import type {Handle} from '@/types';
import PageLayout from '@/components/shared/Page/PageLayout';
import {pageLoader} from '@/utils/server/loader/pageLoader';
import PartnerDirectoryHeaderComponent from '@/pages/shopify.com/($locale)/partners/directory/components/Header/Header';
import {ColorSchemeEnum, HeaderBackgroundColorScheme} from '@/enums';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import Section from '@/components/base/layouts/Section/Section';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import CardGrid from '@/components/base/layouts/CardGrid/CardGrid';
import Button from '@/components/base/elements/Button/Button';
import SideBySide from '@/components/base/modules/SideBySide/SideBySide';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {DIRECTORY_CACHE_TTL} from '@/pages/shopify.com/($locale)/partners/directory/utilities/constants';
import Image from '@/components/base/elements/Image/Image';
import type {HeaderProps} from '@/components/brochureV2/section/Header/types';

import secondaryNavFn from './components/Header/secondaryNavFn';
import HeroFloatingImageWithSearch from './components/Hero/HeroFloatingImageWithSearch';
import ContentCard from './components/ContentCard/ContentCard';
import MarketingIcon from './components/MarketingIcon/MarketingIcon';
import DevelopmentIcon from './components/DevelopmentIcon/DevelopmentIcon';
import OnlineStoreIcon from './components/OnlineStoreIcon/OnlineStoreIcon';
import DesignIcon from './components/DesignIcon/DesignIcon';
import BlogPostIcon from './components/BlogPostIcon/BlogPostIcon';
import UserProfileIcon from './components/UserProfileIcon/UserProfileIcon';
import {partnersDirectoryLoader} from './server/partnersDirectoryLoader.server';

export const handle: Handle = {
  shareImage:
    'https://cdn.shopify.com/b/shopify-brochure2-assets/b659c83a85f891274ca196187bbb8904.png?originalWidth=1200&originalHeight=630',
};

export const headers: HeadersFunction = () => ({
  'Cache-Control': `max-age=${DIRECTORY_CACHE_TTL}`,
});

export {partnersDirectorySearchAction as action} from './server/partnersDirectorySearchAction.server';

export const loader: LoaderFunction = async (args: LoaderFunctionArgs) => {
  const [routesData, partnerDirectoryData] = await Promise.all([
    pageLoader(args),
    partnersDirectoryLoader(args),
  ]);

  return json({
    ...routesData,
    ...partnerDirectoryData,
  });
};

export default function PartnersDirectory() {
  const {t, localizePath} = useTranslations();
  const {t: sharedTranslations} = useSharedTranslations(
    'pages/partners/directory/shared',
  );
  const loaderData = useLoaderData<any>();
  const user = loaderData.user;

  const serviceCards = [
    {
      name: 'marketingAndSales',
      icon: <MarketingIcon />,
      link: 'marketing-and-sales',
    },
    {
      name: 'storeSetup',
      icon: <OnlineStoreIcon />,
      link: 'store-setup',
    },
    {
      name: 'developmentAndTroubleshooting',
      icon: <DevelopmentIcon />,
      link: 'development-and-troubleshooting',
    },
    {
      name: 'visualContentAndBranding',
      icon: <DesignIcon />,
      link: 'visual-content-and-branding',
    },
    {
      name: 'contentWriting',
      icon: <BlogPostIcon />,
      link: 'content-writing',
    },
    {
      name: 'expertGuidance',
      icon: <UserProfileIcon />,
      link: 'expert-guidance',
    },
  ];

  const Header = useMemo(
    () =>
      function PartnerDirectoryHeader(props: HeaderProps) {
        return (
          <PartnerDirectoryHeaderComponent
            {...props}
            user={user ? {email: user.email} : undefined}
            background={HeaderBackgroundColorScheme.White}
            secondaryNavFn={secondaryNavFn}
            secondaryNavPageTitle={sharedTranslations('secondaryNav.overview')}
          />
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  return (
    <PageLayout
      isPureTitle
      header={Header}
      footer={
        <Footer
          colorScheme={ColorSchemeEnum.Dark}
          className="bg-partners-purple"
        />
      }
    >
      <HeroFloatingImageWithSearch
        colorScheme={ColorSchemeEnum.White}
        className="bg-home-hero-gradient"
        headingGroupProps={{
          headingHtml: t('hero.heading'),
          subheadHtml: t('hero.subhead'),
        }}
      />

      <Section
        sectionName="ExploreServices"
        componentName="partners-directory-explore-services"
        className="container"
        topSpacing="2xl"
        bottomSpacing="2xl"
      >
        <HeadingGroup
          center
          headingHtml={t('services.heading')}
          subheadHtml={t('services.subhead')}
          size="t4"
        />
        <CardGrid numberOfColumns={3}>
          {serviceCards.map((service) => (
            <ContentCard
              key={service.name}
              link={service.link}
              headingGroup={{
                headingHtml: t(`services.${service.name}.heading`),
                headingAs: 'h3',
                subheadHtml: t(`services.${service.name}.content`),
              }}
              size="small"
              icon={service.icon}
            />
          ))}
        </CardGrid>
      </Section>

      <Section
        sectionName="HireAProfessional"
        componentName="partners-directory-hire-a-professional"
        className="pt-0"
        bottomSpacing="2xl"
      >
        <SideBySide
          image={{
            alt: t('professional.image.alt'),
            className: `max-h-[25rem]`,
            src: `https://cdn.shopify.com/b/shopify-brochure2-assets/c3c914e29d3cabbd3c3b564d1b149494.png?originalWidth=1608&originalHeight=1211`,
            sources: [
              {
                media: `(max-width: 899px)`,
                srcSet: `https://cdn.shopify.com/b/shopify-brochure2-assets/c3c914e29d3cabbd3c3b564d1b149494.png?originalWidth=1608&originalHeight=1211`,
              },
              {
                media: `(min-width: 900px)`,
                srcSet: `https://cdn.shopify.com/b/shopify-brochure2-assets/c3c914e29d3cabbd3c3b564d1b149494.png?originalWidth=804&originalHeight=605`,
              },
            ],
          }}
          visualPosition="right"
          mode="light"
        >
          <div className="mt-auto">
            <HeadingGroup
              headingHtml={t('professional.heading')}
              subheadHtml={t('professional.subhead')}
              size="t4"
            />
            <Button
              href={localizePath('/partners/directory/services')}
              className="w-full sm:w-fit mt-6 mb-10 md:mb-16 text-center"
            >
              {t('services.cta')}
            </Button>
          </div>
        </SideBySide>
      </Section>

      <Section
        sectionName="DiscoverThePlusPartnerDirectory"
        componentName="discover-the-plus-partner-directory"
        className="pt-0"
        bottomSpacing="2xl"
      >
        <SideBySide
          image={{
            alt: t('plusDirectory.image.alt'),
            className: `max-h-[25rem] float-left`,
            src: `https://cdn.shopify.com/b/shopify-brochure2-assets/813f3f19abf05ee206d9443198fcfc03.png?originalWidth=1614&originalHeight=1209`,
            sources: [
              {
                media: `(max-width: 899px)`,
                srcSet: `https://cdn.shopify.com/b/shopify-brochure2-assets/813f3f19abf05ee206d9443198fcfc03.png?originalWidth=1614&originalHeight=1209`,
              },
              {
                media: `(min-width: 900px)`,
                srcSet: `https://cdn.shopify.com/b/shopify-brochure2-assets/813f3f19abf05ee206d9443198fcfc03.png?originalWidth=1614&originalHeight=1209`,
              },
            ],
          }}
          visualPosition="left"
          mode="light"
        >
          <div className="my-auto">
            <HeadingGroup
              headingHtml={t('plusDirectory.heading')}
              subheadHtml={t('plusDirectory.subhead')}
              size="t4"
            />
            <Button
              href={localizePath('/partners/directory/plus')}
              className="w-full sm:w-fit mt-6 text-center"
            >
              {t('plusDirectory.cta')}
            </Button>
          </div>
        </SideBySide>
      </Section>

      <Section
        sectionName="HowItWorks"
        componentName="partners-directory-how-it-works"
        className="bg-[#F4F4F5] py-10 md:py-20"
      >
        <div className="container">
          <HeadingGroup
            headingHtml={t('howItWorks.heading')}
            size="t3"
            mode="light"
            className="mb-8 md:mb-16"
          />
          <CardGrid
            numberOfColumns={3}
            className="gap-x-20"
            extraVerticalSpacing
          >
            <div className="flex flex-col items-stretch justify-start">
              <h3 className="py-4 px-7 bg-how-it-works-gradient text-2xl text-white inline-block w-fit rounded-lg">
                1
              </h3>
              <HeadingGroup
                headingHtml={t('howItWorks.searchAndEvaluate.heading')}
                subheadHtml={t('howItWorks.searchAndEvaluate.content')}
                size="t5"
                className="mt-6"
                mode="light"
              />
            </div>
            <div className="flex flex-col items-stretch justify-start">
              <h3 className="py-4 px-7 bg-how-it-works-gradient text-2xl text-white inline-block w-fit rounded-lg">
                2
              </h3>
              <HeadingGroup
                headingHtml={t('howItWorks.connectAndAgree.heading')}
                subheadHtml={t('howItWorks.connectAndAgree.content')}
                size="t5"
                className="mt-6"
                mode="light"
              />
            </div>
            <div className="flex flex-col items-stretch justify-start">
              <h3 className="py-4 px-7 bg-how-it-works-gradient text-2xl text-white inline-block w-fit rounded-lg">
                3
              </h3>
              <HeadingGroup
                headingHtml={t('howItWorks.collaborateAndGrow.heading')}
                subheadHtml={t('howItWorks.collaborateAndGrow.content')}
                size="t5"
                className="mt-6"
                mode="light"
              />
            </div>
          </CardGrid>
        </div>
      </Section>

      <Section
        sectionName="JoinDirectory"
        componentName="partners-directory-join-directory"
        className="container flex gap-4 md:gap-8 items-center flex-col md:flex-row justify-center sm:justify-normal py-lg"
      >
        <div className="flex-none relative h-fit">
          <Image
            alt={t('join.image.alt')}
            className="rounded-full overflow-hidden z-10 relative mt-3 h-[7.5rem] w-[7.5rem]"
            src="https://cdn.shopify.com/b/shopify-brochure2-assets/fac387f7c1435dca3730a1ba330de53e.png?originalWidth=456&originalHeight=457"
            sources={[
              {
                media: `(max-width: 899px)`,
                srcSet: `https://cdn.shopify.com/b/shopify-brochure2-assets/fac387f7c1435dca3730a1ba330de53e.png?originalWidth=456&originalHeight=457`,
              },
              {
                media: `(min-width: 900px)`,
                srcSet: `https://cdn.shopify.com/b/shopify-brochure2-assets/fac387f7c1435dca3730a1ba330de53e.png?originalWidth=228&originalHeight=228`,
              },
            ]}
          />
          <div className="absolute rounded-full z-0 bg-gradient-to-r from-[#6339C1] to-[#7E24AA] w-[4.5rem] h-[4.5rem] top-1 -left-4"></div>
        </div>
        <HeadingGroup
          headingHtml={t('join.heading')}
          subheadHtml={t('join.subhead')}
          size="t5"
          className="flex-1"
        />
        <Button
          href="https://help.shopify.com/en/partners/directory"
          className="py-3 md:py-2 w-full md:w-auto text-center"
        >
          {t('join.cta')}
        </Button>
      </Section>

      <Section
        sectionName="Disclaimer"
        componentName="partners-directory-disclaimer"
        className="bg-slate-100 m-0 py-md"
      >
        <HeadingGroup
          headingHtml={t('disclaimer.heading')}
          subheadHtml={t('disclaimer.subhead')}
          size="t7"
          className="container"
        />
      </Section>
    </PageLayout>
  );
}
